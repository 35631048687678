import "./App.css";
import hero from "./berriandbear.png";
import logo from "./logo.png";

function App() {
  return (
    <div className="flex h-screen">
      <img className="w-1/2" alt="baby room" src={hero} />
      <div className="flex flex-col justify-center ml-32 h-full">
        <img className="w-3/5 " alt="berri and bear logo" src={logo} />
        <div className="flex flex-col h-64 justify-between ">
          <div>
            <h1 className="text-5xl text-[#E0BDA6]">
              Something <span className="font-medium text-[#C4DBD6]">cute</span>{" "}
              is
              <br />
              coming soon...
              <br />
            </h1>
          </div>
          <div className="space-y-2">
            <p className="text-3xl text-[#E0BDA6]">Signup for updates!</p>
            <div>
              <input
                className="border-2 border-stone-100 w-1/2 p-1 rounded-bl-md rounded-tl-md"
                type="text"
                id="fname"
                name="fname"
                placeholder="email address"
              />
              <button
                className="rounded-br-md rounded-tr-md bg-[#E0BDA6] px-1 py-1 border-2 border-[#E0BDA6] text-white"
                type="button"
              >
                notify!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
